export const experienceData = [
  {
    id: 1,
    company: "CloudfFctory",
    jobtitle: "Cloud Sourcing Speacilist",
    startYear: "Dec 2019",
    endYear: "Present",
  },
  {
    id: 2,
    company: "DG Hub",
    jobtitle: "NodeJS Intern",
    startYear: "Aug 2022",
    endYear: "Oct 2022",
  },
];
