export const educationData = [
  {
    id: 1,
    institution: "Patan Multiple Campus",
    course: "Bachelor Degree in BSc. CSIT",
    startYear: "2021",
    endYear: "Present",
  },
  {
    id: 2,
    institution: "Trinity International College",
    course: "Higher Secondary",
    startYear: "2017",
    endYear: "2019",
  },
];
