// import eight from "../assets/svg/projects/eight.svg";
// import five from "../assets/svg/projects/five.svg";
// import one from "../assets/svg/projects/one.svg";
// import seven from "../assets/svg/projects/seven.svg";
// import six from "../assets/svg/projects/six.svg";
// import three from "../assets/svg/projects/three.svg";
import reaction from "../assets/svg/projects/reaction.png";

export const projectsData = [
  {
    id: 1,
    projectName: "Reaction Timer",
    projectDesc: "This project about testing user's reaction time.",
    tags: ["Vue", "JS"],
    code: "https://github.com/AnupKaphle58/reaction-timer",
    demo: "https://happy-franklin-679743.netlify.app/",
    image: reaction,
  },
  //   {
  //     id: 2,
  //     projectName: "Jewelry Niche",
  //     projectDesc:
  //       "This project is a Jewelry shop, Customer can be buy product and manage them and make payment.",
  //     tags: ["React", "Express", "Material Ui"],
  //     code: "https://github.com/said7388/jewelry-niche",
  //     demo: "https://pandora-web.netlify.app/",
  //     image: seven,
  //   },
  //   {
  //     id: 3,
  //     projectName: "Travel Agency",
  //     projectDesc: "This project about travel agency management.",
  //     tags: ["React", "CSS", "Material Ui"],
  //     code: "https://github.com/said7388/Travel-Agency-client",
  //     demo: "https://travel-agency-fc58b.web.app/",
  //     image: eight,
  //   },
  //   {
  //     id: 5,
  //     projectName: "E-Commerce App",
  //     projectDesc: "A Simple E-commerce application",
  //     tags: ["React Native", "Firebase"],
  //     code: "https://github.com/hhhrrrttt222111/developer-portfolio",
  //     demo: "https://github.com/hhhrrrttt222111/developer-portfolio",
  //     image: five,
  //   },
  //   {
  //     id: 6,
  //     projectName: "Uber Lite",
  //     projectDesc: "Uber clone",
  //     tags: ["Flutter"],
  //     code: "https://github.com/hhhrrrttt222111/developer-portfolio",
  //     demo: "https://github.com/hhhrrrttt222111/developer-portfolio",
  //     image: one,
  //   },
  //   {
  //     id: 7,
  //     projectName: "Stock Market App",
  //     projectDesc: "A simple stock market API app",
  //     tags: ["React", "Redux", "Bootstrap"],
  //     code: "https://github.com/hhhrrrttt222111/developer-portfolio",
  //     demo: "https://github.com/hhhrrrttt222111/developer-portfolio",
  //     image: two,
  //   },
  //   {
  //     id: 8,
  //     projectName: "Car Pooling System",
  //     projectDesc:
  //       "The carpooling system merges multiple people in a car which leads to meet new people, reduces pollution",
  //     tags: ["Flutter", "React"],
  //     code: "https://github.com/hhhrrrttt222111/developer-portfolio",
  //     demo: "https://github.com/hhhrrrttt222111/developer-portfolio",
  //     image: three,
  //   },
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
