export const headerData = {
  name: "Anup Kafle",
  title: "Hello! I am",
  desciption:
    "Hello! I am ANUP KAFLE, a professional and passionate programmer in my daily life. A quick learner with a self-learning attitude. I love to learn and explore new technologies and am Passionate about Problem Solving. ",
  image: "https://i.ibb.co/4WTYXpn/IMG-0480.jpg",
  imagebw: "https://i.ibb.co/BLT8J1K/IMG-0480.jpg",
  resumePdf:
    "https://drive.google.com/file/d/1nXycH6QxErB4vTzURn9holl6kcXbBHtx/view?usp=share_link",
};
